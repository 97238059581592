import {DestroyRef, effect, inject, Injectable, signal} from '@angular/core';
import {takeUntilDestroyed, toObservable} from '@angular/core/rxjs-interop';
import {catchError, Observable, of, tap} from 'rxjs';
import {PopUpDto, PopUpDtoStatusEnum, PopupsRestService} from '@shared/core/api/generated/msa-portal';

@Injectable({
  providedIn: 'root'
})
export class PopupsService {
  private popupsRestService = inject(PopupsRestService);
  private destroyRef = inject(DestroyRef);

  public popups = signal<Array<PopUpDto> | null>(null);
  public popups$ = toObservable(this.popups);

  constructor() {
    this.fetchPopups();
    effect(() => {
      console.log(this.popups());
    });
  }

  fetchPopups() {
    this.popupsRestService
      .getPopups()
      .pipe(
        catchError(() => {
          this.popups.set([]);
          return of([]);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(body => this.popups.set(body));
  }

  dismissPopup(popupDto: PopUpDto): Observable<PopUpDto> {
    if (popupDto.status === PopUpDtoStatusEnum.Inactive) {
      return this.popupsRestService.dismissPopup(popupDto).pipe(
        tap(result => {
          if (result && this.popups()) {
            this.popups.update(currentPopups => currentPopups!.map(p => (p.type === result.type ? result : p)));
          }
        })
      );
    } else {
      this.popups.update(currentPopups => currentPopups!.map(p => (p.type === popupDto.type ? popupDto : p)));
      return of(popupDto);
    }
  }
}
