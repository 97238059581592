@if (cardTitle()) {
  <div class="tw-mb-12 tw-flex tw-items-center tw-justify-between d:tw-mb-18 d:tw-justify-start d:tw-gap-x-6">
    <h2 class="msa-text-h2">{{ cardTitle() | safeTranslate }}</h2>
    @if (tooltipTitle() && tooltipContent()) {
      <msa-tooltip [tooltipTitle]="tooltipTitle()" [tooltipContent]="tooltipContent()" />
    }
  </div>
}
<div class="tw-relative" [class]="activeCard() ? 'tw-bg-black-light-1' : 'tw-bg-rg-black-light-1'">
  <div class="tw-px-18 tw-py-24 d:tw-me-[72px] d:tw-min-h-[72px] d:tw-p-30" [class.!tw-p-0]="noPadding()">
    <ng-content />
  </div>
  @if (menuItems() && menuItems()!.length > 0) {
    <button
      class="!tw-absolute tw-right-0 tw-top-0 tw-h-[42px] tw-w-[42px] tw-bg-navy d:tw-h-[72px] d:tw-w-[72px]"
      #matMenuTrigger="matMenuTrigger"
      [attr.cy-data]="'openMenu'"
      [matMenuTriggerFor]="menu"
      mat-icon-button
    >
      <mat-icon>
        {{ matMenuTrigger.menuOpen ? "close" : "more_vert" }}
      </mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      @for (menuItem of menuItems(); track menuItem) {
        <button (click)="openDialog(menuItem.component)" mat-menu-item>
          {{ menuItem.menuItemTitle | safeTranslate }}
        </button>
      }
    </mat-menu>
  }
</div>
@if (footNote()) {
  <app-msa-content-notice [footNoteBody]="footNoteBody()" type="Info">
    {{ footNote() | safeTranslate }}
  </app-msa-content-notice>
}
