<div class="tw-align-center tw-flex tw-flex-col">
  <div class="tw-relative tw-flex tw-flex-row tw-content-center tw-items-center tw-gap-9 tw-text-black-light-5">
    <input
      class="tw-peer tw-h-18 tw-w-18 tw-shrink-0 tw-appearance-none tw-border-2 tw-border-black-light-5 checked:tw-bg-white hover:tw-border-black-light-7 disabled:tw-border-black-light-1 disabled:tw-bg-black-light-2 disabled:checked:tw-bg-black-light-2 d:tw-h-21 d:tw-w-21 [&.ng-invalid.ng-touched]:tw-border-vermillion-light-2"
      #checkboxInput
      [id]="id()"
      [formControl]="hostControlDirective.control()"
      [attr.data-cy]="'input-' + id()"
      (change)="checked.emit(checkboxInput.checked)"
      type="checkbox"
    />
    <svg
      class="tw-pointer-events-none tw-absolute tw-m-auto tw-hidden tw-h-18 tw-w-18 tw-text-black peer-checked:tw-block d:tw-h-21 d:tw-w-21"
      width="100"
      height="100"
      viewBox="-4 -6.5 23 23"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <!-- Original path of the tick (checkmark) -->
      <path
        d="M5.66675 11.0035L0.666748 6.0035L2.07675 4.5935L5.66675 8.1735L13.2567 0.583496L14.6667 2.0035L5.66675 11.0035Z"
        fill="currentColor"
      />
    </svg>

    <div
      class="msa-text peer-checked:tw-text-white peer-hover:tw-text-black-light-7 peer-disabled:tw-text-black-light-3 peer-[&.ng-invalid.ng-touched]:tw-text-vermillion-light-2"
    >
      @if (label(); as labelText) {
        <label [for]="id()" [appMarkRequired]="hostControlDirective.control()">
          {{ labelText | translate }}
        </label>
      }
      <ng-content />
    </div>
  </div>

  @if (hostControlDirective.control().touched) {
    @for (error of hostControlDirective.control().errors | keyvalue; track error) {
      <p class="msa-text tw-mt-12 tw-text-vermillion-light-2">{{ error.value.text | translate: error.value.params }}</p>
    }
  }
</div>
