<div class="d:tw-grid d:tw-grid-cols-3">
  <div class="tw-px-21 tw-py-30 d:tw-col-span-2 d:tw-px-45 d:tw-py-60">
    <h2 class="msa-text-h2">{{ "i18n.WalletPopup.title" | safeTranslate }}</h2>
    <div class="tw-mt-15 tw-flex tw-flex-col tw-gap-y-6 d:tw-mt-18 d:tw-gap-y-9">
      <p class="msa-text">{{ "i18n.WalletPopup.paragraph-1" | safeTranslate }}</p>
      <p class="msa-text">{{ "i18n.WalletPopup.paragraph-2" | safeTranslate }}</p>
      <ul class="msa-text tw-list-disc">
        <li class="tw-ml-[1.5em]">{{ "i18n.WalletPopup.point-1" | safeTranslate }}</li>
        <li class="tw-ml-[1.5em]">{{ "i18n.WalletPopup.point-2" | safeTranslate }}</li>
        <li class="tw-ml-[1.5em]">{{ "i18n.WalletPopup.point-5" | safeTranslate }}</li>
      </ul>
    </div>
    <div class="tw-mt-18 max-d:tw-hidden">
      <app-msa-content-notice type="Info">
        {{ "i18n.WalletPopup.info" | safeTranslate }}
        <qrcode
          [qrdata]="'i18n.WalletPopup.link' | safeTranslate"
          [allowEmptyString]="false"
          [scale]="1"
          [width]="94"
          [margin]="1"
          cssClass="tw-w-[94px]"
          elementType="img"
          errorCorrectionLevel="M"
          noticeImage
        />
      </app-msa-content-notice>
    </div>
    <div class="tw-mt-18 d:tw-hidden">
      <msa-button (click)="openWalletAppLink()" color="secondary">
        {{ "i18n.WalletPopup.action" | safeTranslate }}
      </msa-button>
    </div>
    <div class="tw-mt-30 d:tw-mt-60">
      <msa-button (click)="dismissWalletPopup()" color="primary">
        {{ "i18n.WalletPopup.dismiss" | safeTranslate }}
      </msa-button>
    </div>
    <div class="tw-mt-12 tw-flex d:tw-mt-18">
      <msa-checkbox id="checkbox" (checked)="toggleDismissForever()" label="i18n.WalletPopup.checkbox" />
    </div>
  </div>
  <div class="tw-relative max-d:tw-hidden d:tw-col-span-1">
    <img class="tw-object-cover" fill ngSrc="assets/images/wallet/wallet_app_in_hand.jpg" alt="" />
  </div>
</div>
